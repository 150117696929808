const config = require('./data.json');

const REVIEW_STATUS = {
  waiting: '稿件未上傳',
  pending: '等待審稿',
  reupload: '已補件',
  accepted: '合格',
  rejected: '不合格',
  void: '作廢',
};

const PRODUCTION_STATUS = {
  waiting: '等待中',
  pending: '-',
  preparing: '工單已交付',
  in_production: '製作中',
  completed: '印製完成',
  in_storage: '已入庫',
};

const INVOICE_CATEGORY = {
  B2B: '三聯式發票',
  B2C: '二聯式發票',
};

const INVOICE_TYPES = {
  two_copies: {
    idx: 0,
    label: '二聯式發票',
    value: 'two_copies',
  },
  three_copies: {
    idx: 1,
    label: '三聯式發票',
    value: 'three_copies',
  },
  donate: {
    idx: 2,
    label: '捐贈發票',
    value: 'donate',
  },
};

const TWO_COPIES_TYPES = {
  citizen_personal_certificate: {
    idx: 0,
    label: '自然人憑證條碼',
    value: 'citizen_personal_certificate',
  },
  mobile_vehicle: {
    idx: 1,
    label: '手機載具',
    value: 'mobile_vehicle',
  },
  ezpay_vehicle: {
    idx: 2,
    label: 'ezPay 電子發票載具',
    value: 'ezpay_vehicle',
  },
};

const LOVECODE_OPTIONS = [
  {
    value: '',
    label: '請選擇',
  },
  {
    value: '8999',
    label: '中華民國荒野保護協會',
  },
  {
    value: '0958',
    label: '台灣動物緊急救援推廣協會',
  },
  {
    value: '3405',
    label: '台灣懷生相信動物協會',
  },
  {
    value: '7299',
    label: '中華民國保護動物協會',
  },
  {
    value: '4545',
    label: '台灣全民食物銀行協會',
  },
  {
    value: '5245',
    label: '台灣食物銀行聯合會',
  },
];

const PAYMENT_TYPES = [
  {
    label: '信用卡付款',
    value: 'credit',
    intro: `前往付款時將開啟信用卡付款頁面，填入卡片資料即完成。交易時間過期銀行系統將無法接受付款。`,
  },
  {
    label: 'ATM 代碼轉帳',
    value: 'atm',
    intro: `每筆訂單會產生一組銀行繳款帳號，ATM 轉帳帳號均為虛擬繳款帳號，繳費完成系統將自動進行對帳作業。`,
  },
  {
    label: '超商代碼繳費 (非貨到超商付款)',
    value: 'cvs',
    intro: `超商代碼繳費 (非貨到超商付款)，每筆訂單會產生一組超商繳費代碼，代碼繳費超商會收取28元代收手續費，付款後若需退款將無法退還手續費。`,
  },
  {
    label: 'LINE PAY 付款',
    value: 'line_pay',
    intro: `開啟手機 LINE App 掃描螢幕上的 QRcode 條碼，手機畫面將會跳轉至付款流程。`,
  },
  {
    value: 'offline',
    label: '臨櫃匯款',
    intro: `
      請依訂單金額（含手續費）匯款，並於訂單頁填寫 <strong>匯款戶名、後五碼、匯款日期</strong>。完成後，請加 Line <strong>@li_xiang_print</strong> 聯繫客服，並傳送匯款確認訊息。
      <br />
      <span class="custom-color">注意：若未主動告知，訂單將不會進入審稿流程。</span>
    `,
  },
  ...(config.isLiteVersion
    ? []
    : [
        {
          label: '點數付款',
          value: 'credits',
          intro: `加值金額及為現金點數（加值1元 = 點數1點），下單後訂單費用直接扣除您的加值現金點數。`,
        },
      ]),
  {
    label: '月結付款 (僅限月結會員)',
    memberType: 'monthly',
    value: 'monthly',
    intro: `月結付款的訂單依照會員當期的額度，先下單審核並製作，結算日到期時匯款至指定帳戶，若累計消費超過區間額度將無法下單，若預期未繳賬號被凍結，也同樣無法下單`,
  },
  {
    label: '訂閱制',
    value: 'period',
    periodOnly: true,
    intro: `訂閱制訂單開啟信用卡付款頁面，完成付款後建立委託單，並於每個月進行扣款。`,
  },
];

const PAYMENT_SUBTYPE = {
  default: '-',
  credit: '信用卡付款',
  cvs_cod: '超商取貨付款',
  cvs: '超商繳費',
  atm: 'ATM付款',
  web_atm: '網路ATM付款',
  barcode: '超商條碼繳費',
};

const ORDER_TYPE = {
  extra: '補收款訂單',
  credit: '點數訂單',
  monthly: '月結訂單',
  custom: '自定義訂單',
  default: '一般訂單',
  period: '訂閱制訂單',
};

const ORDER_STATE = {
  payment_waiting: '等待付款',
  review_waiting: '稿件未上傳',
  review_pending: '等待審稿',
  review_rejected: '不合格',
  production_waiting: '製作中', //"製作等待中"
  /*
    production_preparing 工單已交付
    production_in_production 製作中
    display用order_items算的， filter 用 production_status
  */
  production_preparing: '製作中', //"工單已交付"
  production_in_production: '製作中', //"商品製作中",
  production_completed: '製作完成',
  logistic_pending: '理貨中', //"出貨未處理",
  logistic_packaging: '理貨中', //"打包中",
  logistic_tallying: '出貨中', //"物流未完成",
  logistic_transit: '出貨中', //"物流未完成", // 已出貨
  completed: '訂單完成',
  voided: '訂單已取消',
  refunding: '退款處理中',
  refunded: '已完成退款',
  ass_apply: '售後服務處理中',
};

const ORDER_STATE_ICON = {
  payment_waiting: {
    subtitle: '請點選『前往付款』產生轉帳代碼繳費。',
    icon: '/images/order-icon-payment-waiting.svg',
  },
  review_waiting: {
    subtitle: '檔案未上傳，上傳檔案前不會進審稿流程',
    icon: '/images/order-icon-item-pending.svg',
  },
  review_pending: {
    subtitle: '正等待審稿人員審核稿件。',
    icon: '/images/order-icon-item-unassigned.svg',
  },
  review_rejected: {
    subtitle: '您的稿件審查不合格，請重新上傳稿件。',
    icon: '/images/order-icon-item-rejected.svg',
  },
  production_waiting: {
    subtitle: '稿件審稿合格將安排印製。',
    icon: '/images/order-icon-item-accepted.svg',
  },
  production_preparing: {
    subtitle: '您的商品正在印製，請耐心等候。',
    icon: '/images/order-icon-item-in-production.svg',
  },
  production_in_production: {
    subtitle: '您的商品正在印製，請耐心等候。',
    icon: '/images/order-icon-item-in-production.svg',
  },
  production_completed: {
    subtitle: '您的商品正在等待出貨。',
    icon: '/images/order-icon-item-in-production.svg',
  },
  logistic_pending: {
    subtitle: '訂單正在理貨中，請耐心等候。',
    icon: '/images/order-icon-item-tallying.svg',
  },
  logistic_packaging: {
    subtitle: '訂單正在理貨中，請耐心等候。',
    icon: '/images/order-icon-item-tallying.svg',
  },
  logistic_tallying: {
    subtitle: '訂單正在配送中，請注意物流來電。',
    icon: '/images/order-icon-item-tallying.svg',
  },
  logistic_transit: {
    subtitle: '訂單正在配送中，請注意物流來電。',
    icon: '/images/order-icon-item-tallying.svg',
  },
  completed: {
    subtitle: '此筆訂單已經完成，請於3日內確認商品。',
    icon: '/images/order-icon-completed.svg',
  },
  voided: {
    subtitle: '此訂單已經申請取消，或訂單7天未付款系統取消訂單。',
    icon: '/images/order-icon-failed.svg',
  },
  refunding: {
    subtitle: '退款程序處理中，請檢視有無需要填寫退款資訊',
    icon: '/images/order-icon-refunding.svg',
  },
  refunded: {
    subtitle: '已完成退款',
    icon: '/images/order-icon-refunded.svg',
  },
  ass_apply: {
    subtitle: '正在等候工作人員審核售後服務單',
    icon: '/images/order-icon-after-sales-service-apply.svg',
  },
};

const PAYUNI_PAYMENT_ICON = {
  atm: {
    icon: '/images/payment-icon-atm.webp',
    description: '請點選『前往付款』產生轉帳代碼繳費',
  },
  cvs: {
    icon: '/images/payment-icon-csv.webp',
    description: '請點選『前往付款』產生超商代碼繳費',
  },
  line_pay: {
    icon: '/images/payment-icon-line-pay.webp',
    description: '請點選『 LINE PAY 付款 』在應用程式中直接進行付款',
  },
  credits: {
    icon: '/images/payment-icon-credits.webp',
    description: '請點選『點數付款』進行訂單扣點',
  },
  credit: {
    icon: '/images/payment-icon-credit.webp',
    description: '請輸入信用卡付款資訊付款',
  },
  offline: {
    icon: '/images/payment-icon-offline.webp',
    description: '請依照訂單金額（含手續費）匯款，完成後請回填匯款資訊',
    remittance: '請加 Line @li_xiang_print 聯繫客服確認付款。',
    remittance_subtitle:
      '付款經客服確認且檔案上傳完成後，訂單才會正式排單審稿。',
  },
  default: {
    icon: '/images/payment-icon-customize.svg',
    description: '請點選『前往付款』進行繳費',
  },
};

const EXRA_ORDER_TYPE = {
  custom: '自定義商品',
  item_fee: '稿件加工',
  shipping_fee: '運費',
  construction_funds: '施工款項',
  urgent_increase: '急件加價',
};

const PAYMENT_STATUS = {
  processing: '未付款',
  waiting: '未付款',
  code_generated: '未付款', //已取得交易序號
  success: '已付款',
  failure: '付款失敗',
};

const DEPOSIT_STATUS = {
  waiting: '等待付款',
  processing: '等待付款',
  success: '加值成功', // = payment success 付款成功
  failure: '加值失敗(付款失敗)', // = payment fail 付款失敗
};

const DELIVERY_TYPE = {
  self_pick: '自取',
  hct: '宅配', //新竹貨運
  tcat: '宅配', //黑貓宅急便
  kerry_tj: '宅配', //大榮貨運
  mailing: '宅配', //郵局包裹
  special_car: '專車配送',
  ezship: '台灣便利配',
  payuni: '711 超商取貨',
  xdelivery: '全家 超商取貨',
};

const LOGISTIC_TRACKING_URL = {
  hct: 'https://www.hct.com.tw/search/searchgoods_n.aspx',
  kerry_tj: 'http://www.kerrytj.com/ZH/search/search_track.aspx',
  tcat: 'https://www.t-cat.com.tw/inquire/trace.aspx',
  mailing: 'https://postserv.post.gov.tw/pstmail/main_mail.html',
  ezship:
    'https://www.ezship.com.tw/receiver_query/ezship_query_shipstatus_2017.jsp',
  payuni: 'https://eservice.7-11.com.tw/e-tracking/search.aspx',
  xdelivery: 'https://fmec.famiport.com.tw/FP_Entrance/QueryBox',
};

const LOGISTIC_STATUS = {
  default: '未處理',
  packaging: '打包中',
  tallying: '等待出貨',
  pending: '出貨中',
  transit: '已出貨',
  pickup: '運送中',
  notfound: '無追蹤中包裹',
  delivered: '已送達',
  undelivered: '未送達',
  exception: '例外事故',
  expired: '過期',
};

const LOGISTIC_STATUS_SELF = {
  default: '未處理',
  packaging: '打包中',
  tallying: '等待出貨',
  transit: '可自取', //hct:已出貨
  pickup: '已出貨',
  pending: '出貨中',
  notfound: '無追蹤中包裹',
  delivered: '已自取', //hct: 已送達
  undelivered: '未送達',
  exception: '例外事故',
  expired: '過期',
};

const RETURN_STATUS = {
  pending: '等待處理',
  supplying: '補印件中',
  supplement_completed: '已完成補印件',
  returning: '退款',
  return_completed: '售後服務完成',
  rejected: '退貨條件不符，已拒絕退貨',
};

const REFUND_STATUS = {
  waiting: '退款處理中',
  success: '退款已完成',
  failure: '退款已取消',
};

const REFUND_TYPE = {
  partial: '部分金額轉帳退款',
  transfer_full: '全額轉帳退款',
  full: '全額信用卡刷退',
};

const RETURN_REASON = [
  '重複下單',
  '商品款式錯誤 (例如：尺寸、顏色、圖案不符等)',
  '商品有瑕疵',
  '商品有損毀',
  '其他問題（填入備註）',
];

const VOIDED_REASON = [
  '訂單金額有誤 (例如：商品、運費金額等)',
  '商品數量下單錯誤',
  '商品款式下單錯誤',
  '其他原因',
];

const USER_TYPE = {
  normal: '一般會員',
  vip: '預儲會員',
  enterprise: '企業會員',
  ent_vip: '企業預繳會員',
  monthly: '企業月結會員',
  ent_vip_monthly: '企業月結預儲會員',
};

const UPGRADE_STATUS = {
  //accepted, rejected, pending
  accepted: '申請成功',
  rejected: '申請失敗',
  pending: '等待審核中',
};

const STORE_CHANNEL = {
  TOK: 'OK便利商店',
  TLF: '萊爾富',
  TFM: '全家',
  FAMI: '全家',
  '7-ELEVEN': '7-11',
};

const MONTHLY_TYPE = {
  '1_in_month': '每月1號',
  '5_in_month': '每月5號',
  '10_in_month': '每月10號',
  '15_in_month': '每月15號',
  '20_in_month': '每月20號',
  '25_in_month': '每月25號',
  last_in_month: '每月最後一天',
};

const MONTHLY_STATUS = {
  waiting: '未繳費',
  remitted: '待對帳',
  success: '已繳費',
  expired: '已過期',
};

const MONTHLY_ORDER_STATUS_COLOR = {
  waiting: '#c6c6c6',
  remitted: '#4690f7',
  success: '#9acf6f',
  expired: '#d0543c',
};

const PERIOD_STATUS = {
  activity: '啟用', //（預設）
  review_waiting: '申請取消中',
  extra_payment_waiting: '補收款中',
  rejected: '拒絕取消',
  terminate: '終止',
};

export {
  REVIEW_STATUS,
  PRODUCTION_STATUS,
  LOVECODE_OPTIONS,
  PAYMENT_TYPES,
  PAYMENT_SUBTYPE,
  ORDER_TYPE,
  ORDER_STATE,
  ORDER_STATE_ICON,
  PAYUNI_PAYMENT_ICON,
  EXRA_ORDER_TYPE,
  PAYMENT_STATUS,
  DEPOSIT_STATUS,
  DELIVERY_TYPE,
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_SELF,
  LOGISTIC_TRACKING_URL,
  INVOICE_CATEGORY,
  INVOICE_TYPES,
  TWO_COPIES_TYPES,
  RETURN_STATUS,
  REFUND_STATUS,
  REFUND_TYPE,
  RETURN_REASON,
  VOIDED_REASON,
  USER_TYPE,
  UPGRADE_STATUS,
  STORE_CHANNEL,
  MONTHLY_TYPE,
  MONTHLY_STATUS,
  MONTHLY_ORDER_STATUS_COLOR,
  PERIOD_STATUS,
};
